import React, { useState, lazy, useEffect } from "react";
import { Breadcrumb, Button, Dropdown, Image, Spinner } from "react-bootstrap";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { AuthCheckCustom } from "./Settings/AuthCheckCustom";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import NoAuth from "./NoAuth";

import { BsList, BsTv } from "react-icons/bs";
import {
  HiArrowLeft,
  HiLibrary,
  HiOutlineCash,
  HiOutlineCog,
  HiSearch,
  HiUserGroup,
} from "react-icons/hi";
import DALogo from "../assets/images/DALogo.png";
import { useAuth, useFirestore, useUser } from "reactfire";
import { Box, DoorClosed, GraphUp } from "react-bootstrap-icons";
import { FaGavel, FaTruckMoving } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { connectFirestoreEmulator } from "firebase/firestore";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// Imports not lazy loaded
import AutogateListingsComponent from "./Insights/AutogateListingsComponent";

// @COMPATIBILITY
import { BiExit, BiHome, BiPin } from "react-icons/bi";
import Start from "./Start";
import toast, { Toaster } from "react-hot-toast";

// LAZY LOADING

const SugarLeadGenerationComponent = lazy(() =>
  import("./Buying/SugarLeadGenerationComponent")
);

const InsightsAMWComponent = lazy(() =>
  import("./Insights/InsightsAMWComponent")
);

const InventoryHealth = lazy(() => import("./Insights/InventoryHealth"));

const ReconStockLocationAMW = lazy(() =>
  import("./Insights/ReconStockLocationAMW")
);

const RetailSalesKPIComponent = lazy(() =>
  import("./Insights/RetailSalesKPIComponent")
);

const DABuyingBoard = lazy(() => import("./Buying/DABuyingBoard"));

const SIDManagement = lazy(() => import("./Buying/SIDManagement"));

const InventoryManagementComponent = lazy(() =>
  import("./Insights/InventoryManagementComponent")
);

const InventoryHealthDuttonOne = lazy(() =>
  import("./Insights/InventoryHealthDuttonOne")
);

const RetailSalesKPID1VICComponent = lazy(() =>
  import("./Insights/RetailSalesKPID1VICComponent")
);

const InventoryHealthDuttonOneQLD = lazy(() =>
  import("./Insights/InventoryHealthDuttonOneQLD")
);

const ReconStockLocationD1QLD = lazy(() =>
  import("./Insights/ReconStockLocationD1QLD")
);

const InventoryHealthDuttonOneNSW = lazy(() =>
  import("./Insights/InventoryHealthDuttonOneNSW")
);

const InventoryHealthDuttonImports = lazy(() =>
  import("./Insights/InventoryHealthDuttonImports")
);

const DuttonOneSoldCalendarComponent = lazy(() =>
  import("./Insights/DuttonOneSoldCalendarComponent")
);

const BuyingDataComponent = lazy(() =>
  import("./Insights/BuyingDataComponent")
);

const XeroDataEntryComponent = lazy(() =>
  import("./Finance/Accounts/XeroDataEntryComponent")
);

const FreightBookingForm = lazy(() =>
  import("./FreightBooking/FreightBookingForm")
);

const MyFreightBookingList = lazy(() =>
  import("./FreightBooking/MyFreightBookingList")
);

const FreightBookingListWithRouting = lazy(() =>
  import("./FreightBooking/FreightBookingListWithRouting")
);

const FreightListFilteredTableView = lazy(() =>
  import("./FreightBooking/FreightListFilteredTableView")
);

const FreightListFilteredTableViewNew = lazy(() =>
  import("./FreightBooking/FreightListFilteredTableViewNew")
);

const FreightBookingItem = lazy(() =>
  import("./FreightBooking/FreightBookingItem")
);

const FreightBookingListWholesale = lazy(() =>
  import("./FreightBooking/FreightBookingListWholesale")
);

const ListCarriers = lazy(() =>
  import("./FreightBooking/Options/ListCarriers")
);

const ListMyAddressBook = lazy(() =>
  import("./FreightBooking/Options/ListMyAddressBook")
);

const FreightDashboardSummary = lazy(() =>
  import("./FreightBooking/Reports/FreightDashboardSummary")
);

const UpdateUserComponent = lazy(() =>
  import("./Settings/UpdateUserComponent")
);

const CollateralReportsComponent = lazy(() =>
  import("./Finance/CBA/CollateralReportsComponent")
);

const WallboardWholesale = lazy(() =>
  import("./FreightBooking/WallboardWholesale")
);

const WallboardWholesaleNew = lazy(() =>
  import("./FreightBooking/WallboardWholesaleNew")
);

const ViewUsersComponent = lazy(() => import("./Settings/ViewUsersComponent"));

const VehicleSearchComponent = lazy(() => import("./VehicleSearchComponent"));

const StockCardByVinComponent = lazy(() => import("./StockCardByVinComponent"));

const StockCardComoponent = lazy(() => import("./StockCardComponent"));

const VINDecodeComponent = lazy(() => import("./VINDecodeComponent"));

const PaymentRequestDeposit = lazy(() => import("./PaymentRequestDeposit"));

const InternalTenders = lazy(() => import("./Auctions/InternalTenders"));

const InternalTendersVehicleBidder = lazy(() =>
  import("./Auctions/InternalTendersVehicleBidder")
);

const InternalTendersVehicleSeller = lazy(() =>
  import("./Auctions/InternalTendersVehicleSeller")
);

const BISalesComponent = lazy(() => import("./Insights/BI/BISalesComponent"));

const SideBarMenuItem = ({ items, requiredClaim, hasSubMenu, subMenuInfo }) => {
  if (subMenuInfo.label === "Home") {
    return null;
  }

  if (hasSubMenu) {
    return (
      <AuthCheckCustom requiredClaims={requiredClaim} fallback={null}>
        <SubMenu
          icon={subMenuInfo.icon}
          label={subMenuInfo.label}
          defaultOpen={subMenuInfo.defaultOpen || false}
        >
          {items.map(
            (item, index) =>
              item.showMenuItem && (
                <MenuItem
                  icon={item.icon}
                  key={index}
                  component={<NavLink to={item.to} />}
                  //   active={(match, location) => isActive(match, location)}
                >
                  {item.title}
                </MenuItem>
              )
          )}
        </SubMenu>
      </AuthCheckCustom>
    );
  }

  return (
    <AuthCheckCustom requiredClaims={requiredClaim} fallback={null}>
      {items.map((item, index) => (
        <MenuItem
          icon={item.icon}
          key={index}
          component={<NavLink to={item.to} />}
          active={item.active}
        >
          {item.title}
        </MenuItem>
      ))}
    </AuthCheckCustom>
  );
};

const SuspenseLoadingFallback = () => {
  return (
    // Centered loading spinner
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Spinner animation="border" role="status" size="md" />
    </div>
  );
};

const menuItems = [
  {
    items: [
      {
        title: "Home",
        to: "/",
        showMenuItem: false,
        routeInfo: {
          path: "/",
          component: <Start />,
          requiredClaim: {},
          breadCrumbs: [],
        },
      },
    ],
    requiredClaim: {},
    subMenu: false,
    subMenuInfo: {
      label: "Home",
      icon: <BiHome />,
    },
  },
  {
    items: [
      {
        title: "Sales Dashboard",
        to: "/insights/bi/sales/dashboard",
        showMenuItem: true,
        routeInfo: {
          path: "/insights/bi/sales/dashboard",
          component: <BISalesComponent />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },
            {
              label: "BI",
              pos: 2,
              active: false,
            },
            {
              label: "Sales",
              pos: 3,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <GraphUp />,
    requiredClaim: {
      insights: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "BI",
      icon: <GraphUp />,
    },
  },
  {
    items: [
      // {
      //   title: "AG Listing",
      //   to: "/autogate-listings",
      //   // icon: <Box />,
      //   routeInfo: {
      //     path: "/autogate-listings",
      //     requiredClaim: {
      //       insights: true,
      //     },
      //     component: null,
      //     // component: <AutogateListingsComponent />,
      //     breadCrumbs: [
      //       {
      //         label: "Home",
      //         pos: 0,
      //         active: false,
      //       },
      //       {
      //         label: "AG Listing",
      //         pos: 1,
      //         active: true,
      //       },
      //     ],
      //   },
      // },
      {
        title: "AG Listings",
        icon: <Box />,
        to: "/autogate-listings",
        showMenuItem: true,
        routeInfo: {
          path: "/autogate-listings",
          component: <AutogateListingsComponent />,
          requiredClaim: {
            sid_admin: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Buying",
              pos: 1,
              active: false,
            },
            {
              label: "SID Management",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <Box />,
    requiredClaim: {
      insights: true,
    },
    subMenu: false,
    subMenuInfo: {
      label: "AG Listings",
      icon: <Box />,
    },
  },
  {
    items: [
      {
        title: "Insights",
        to: "/insights-amw",
        showMenuItem: true,
        routeInfo: {
          path: "/insights-amw",
          component: <InsightsAMWComponent />,
          requiredClaim: {
            insights_amw: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "AMW",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Inventory health",
        to: "/inventory-health",
        showMenuItem: true,
        routeInfo: {
          path: "/inventory-health",
          component: <InventoryHealth />,
          requiredClaim: {
            insights_amw: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "AMW",
              pos: 2,
              active: false,
            },
            {
              label: "Inventory health",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Recon stock location",
        to: "/recon-stock-movements",
        showMenuItem: true,
        routeInfo: {
          path: "/recon-stock-movements",
          component: <ReconStockLocationAMW />,
          requiredClaim: {
            insights_amw: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "AMW",
              pos: 2,
              active: false,
            },
            {
              label: "Recon stock location",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Sales KPI AMW",
        to: "/retail-sales-kpi-amw",
        showMenuItem: true,
        routeInfo: {
          path: "/retail-sales-kpi-amw",
          component: <RetailSalesKPIComponent brand="AMW" />,
          requiredClaim: {
            insights_amw: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "AMW",
              pos: 2,
              active: false,
            },
            {
              label: "Retail Sales KPI",
              pos: 3,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <HiUserGroup />,
    requiredClaim: {
      insights_amw: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Insights - AMW",
      icon: <GraphUp />,
    },
  },
  {
    items: [
      {
        title: "Inv Mgmt. National",
        to: "/inventory-management-national",
        showMenuItem: true,
        routeInfo: {
          path: "/inventory-management-national",
          component: <InventoryManagementComponent />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },
            {
              label: "Inventory Management",
              pos: 2,
              active: false,
            },
            {
              label: "Inventory Management",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Inventory health VIC",
        to: "/inventory-health-duttonone",
        showMenuItem: true,
        routeInfo: {
          path: "/inventory-health-duttonone",
          component: <InventoryHealthDuttonOne />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "Dutton One",
              pos: 2,
              active: false,
            },
            {
              label: "Inventory health",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Sales KPI VIC",
        to: "/sales-kpi-duttonone-vic",
        showMenuItem: true,
        routeInfo: {
          path: "/sales-kpi-duttonone-vic",
          component: <RetailSalesKPID1VICComponent brand="D1VIC" />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "Dutton One",
              pos: 2,
              active: false,
            },
            {
              label: "Sales KPI",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Inventory health QLD",
        to: "/inventory-health-duttonone-qld",
        showMenuItem: true,
        routeInfo: {
          path: "/inventory-health-duttonone-qld",
          component: <InventoryHealthDuttonOneQLD />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "Dutton One",
              pos: 2,
              active: false,
            },
            {
              label: "Inventory health",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Recon Lanes QLD",
        to: "/recon-stock-movements-duttonone-qld",
        showMenuItem: true,
        routeInfo: {
          path: "/recon-stock-movements-duttonone-qld",
          component: <ReconStockLocationD1QLD />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "Dutton One",
              pos: 2,
              active: false,
            },
            {
              label: "Recon Lanes QLD",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Inventory health NSW",
        to: "/inventory-health-duttonone-nsw",
        showMenuItem: true,
        routeInfo: {
          path: "/inventory-health-duttonone-nsw",
          component: <InventoryHealthDuttonOneNSW />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "Dutton One",
              pos: 2,
              active: false,
            },
            {
              label: "Inventory health - NSW",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Inventory health Imp.",
        to: "/inventory-health-duttonone-imports",
        showMenuItem: false,
        routeInfo: {
          path: "/inventory-health-duttonone-imports",
          component: <InventoryHealthDuttonImports />,
          requiredClaim: {
            insights_imports: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "Dutton One",
              pos: 2,
              active: false,
            },
            {
              label: "Inventory health - Imports",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Sold and Delivered VIC",
        to: "/sold-calendar-duttonone",
        showMenuItem: true,
        routeInfo: {
          path: "/sold-calendar-duttonone",
          component: <DuttonOneSoldCalendarComponent />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },

            {
              label: "Dutton One",
              pos: 2,
              active: false,
            },
            {
              label: "Sold and Delivered Calendar - VIC",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Autogate Listings",
        to: "/insights/autogate-listings",
        showMenuItem: true,
        routeInfo: {
          path: "/insights/autogate-listings",
          component: <AutogateListingsComponent />,
          requiredClaim: {
            insights: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Insights",
              pos: 1,
              active: false,
            },
            {
              label: "Autogate Listings",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <GraphUp />,
    requiredClaim: {
      insights: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Insights - D1",
      icon: <GraphUp />,
    },
  },
  {
    items: [
      {
        title: "DABS Buying Board",
        to: "/buying/da-buying-board",
        showMenuItem: true,
        routeInfo: {
          path: "/buying/da-buying-board",
          component: <DABuyingBoard />,
          requiredClaim: {
            buying_view: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Buying",
              pos: 1,
              active: false,
            },

            {
              label: "DABS Buying Board",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "SID Admin",
        to: "/buying/sid-management",
        showMenuItem: true,
        routeInfo: {
          path: "/buying/sid-management",
          component: <SIDManagement />,
          requiredClaim: {
            sid_admin: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Buying",
              pos: 1,
              active: false,
            },
            {
              label: "SID Management",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Sugar Lead Gen",
        to: "/sugar-lead-gen/new",
        showMenuItem: true,
        routeInfo: {
          path: "/sugar-lead-gen/new",
          component: <SugarLeadGenerationComponent />,
          requiredClaim: {
            sid_admin: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Buying",
              pos: 1,
              active: false,
            },
            {
              label: "Sugar Lead Generation",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <HiUserGroup />,
    requiredClaim: {
      buying_view: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Buying",
      icon: <HiUserGroup />,
    },
  },
  {
    items: [
      {
        title: "New Deposit Request",
        to: "/deposit-payment-request",
        showMenuItem: true,
        routeInfo: {
          path: "/deposit-payment-request",
          component: <PaymentRequestDeposit />,
          requiredClaim: {
            payment_request_deposit: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Payments",
              pos: 1,
              active: false,
            },

            {
              label: "New Deposit Request",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <MdPayment />,
    requiredClaim: {
      payment_request_deposit: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Payments",
      icon: <MdPayment />,
    },
  },

  {
    items: [
      {
        title: "Internal Vehicle Search",
        to: "/vin-rego-search",
        showMenuItem: true,
        routeInfo: {
          path: "/vin-rego-search",
          component: <VehicleSearchComponent />,
          requiredClaim: {
            vinsearch_r: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Search",
              pos: 1,
              active: false,
            },

            {
              label: "VIN and Rego Search",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "VIN Decode",
        to: "/vin-decode",
        showMenuItem: true,
        routeInfo: {
          path: "/vin-decode",
          component: <VINDecodeComponent />,
          requiredClaim: {
            decodeVIN: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Search",
              pos: 1,
              active: false,
            },
            {
              label: "VIN Decode",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "VIN Details",
        to: "/vin/:vin",
        showMenuItem: false,
        routeInfo: {
          path: "/vin/:vin",
          component: <StockCardByVinComponent />,
          requiredClaim: {
            vinsearch_r: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Search",
              pos: 1,
              active: false,
            },
            {
              label: "Vehicle Details",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Stock Number Details",
        to: "/stock/:stocknumber",
        showMenuItem: false,
        routeInfo: {
          path: "/stock/:stocknumber",
          component: <StockCardComoponent />,
          requiredClaim: {
            vinsearch_r: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Search",
              pos: 1,
              active: false,
            },
            {
              label: "Vehicle Details",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <HiSearch />,
    requiredClaim: {
      vinsearch_r: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Search",
      icon: <HiSearch />,
      defaultOpen: false,
    },
  },
  {
    items: [
      {
        title: "Data Entry Buying",
        to: "/buying/buying-data-entry",
        showMenuItem: true,
        routeInfo: {
          path: "/buying/buying-data-entry",
          component: <BuyingDataComponent />,
          requiredClaim: {
            labs_lk_data_entry: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Labs",
              pos: 1,
              active: false,
            },

            {
              label: "Data Entry Buying",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Data Entry AP",
        to: "/finance/accounts/apai",
        showMenuItem: true,
        routeInfo: {
          path: "/finance/accounts/apai",
          component: <XeroDataEntryComponent />,
          requiredClaim: {
            labs_lk_data_entry: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Labs",
              pos: 1,
              active: false,
            },
            {
              label: "Accounts Payable",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <HiUserGroup />,
    requiredClaim: {
      labs_lk_data_entry: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Labs",
      icon: <HiLibrary />,
    },
  },
  {
    items: [
      {
        title: "New Booking",
        to: "/freight-booking-form",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-booking-form",
          component: <FreightBookingForm />,
          requiredClaim: {
            freight_c: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },

            {
              label: "New Booking",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "My Bookings",
        to: "/my-freight-booking-list",
        showMenuItem: true,
        routeInfo: {
          path: "/my-freight-booking-list",
          component: <MyFreightBookingList />,
          requiredClaim: {
            freight_c: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },

            {
              label: "My Bookings",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "List",
        to: "/freight-booking-list",
        showMenuItem: false,
        routeInfo: {
          path: "/freight-booking-list",
          component: <FreightBookingListWithRouting viewModeState="new" />,
          requiredClaim: {
            freight_cru: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },

            {
              label: "List",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "List - Filtered (New)",
        to: "/freight-booking/list/new/:stage",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-booking/list/new/:stage",
          component: (
            <>
              <FreightBookingListWithRouting viewModeState="new" />
              <FreightListFilteredTableViewNew />
            </>
          ),
          requiredClaim: {
            freight_cru: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },
            {
              label: "List",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "List - Filtered (Ancient)",
        to: "/freight-booking/list/ancient/:stage",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-booking/list/ancient/:stage",
          component: (
            <>
              <FreightBookingListWithRouting viewModeState="ancient" />
              <FreightListFilteredTableView />
            </>
          ),
          requiredClaim: {
            freight_cru: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },
            {
              label: "List",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Booking Details",
        to: "/freight-booking-list/:bookingId",
        showMenuItem: false,
        routeInfo: {
          path: "/freight-booking-list/:bookingId",
          component: <FreightBookingItem />,
          requiredClaim: {},
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },
            {
              label: "List",
              pos: 2,
              active: false,
            },
            {
              label: "Booking Details",
              pos: 3,
              active: true,
            },
          ],
        },
      },
      {
        title: "Wholesale List",
        to: "/freight-booking-list-wholesale",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-booking-list-wholesale",
          component: <FreightBookingListWholesale />,
          requiredClaim: {
            freight_list_ro: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },
            {
              label: "Wholesale List",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Carriers",
        to: "/freight-booking-options/list-carriers",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-booking-options/list-carriers",
          component: <ListCarriers />,
          requiredClaim: {
            freight_cru: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },
            {
              label: "List Carriers",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Address Book",
        to: "/freight-address-book",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-address-book",
          component: <ListMyAddressBook />,
          requiredClaim: {
            freight_cru: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },
            {
              label: "Address Book",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Dashboard",
        to: "/reports/freight-dashboard-summary",
        showMenuItem: true,
        routeInfo: {
          path: "/reports/freight-dashboard-summary",
          component: <FreightDashboardSummary />,
          requiredClaim: {
            freight_cru: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Freight Bookings",
              pos: 1,
              active: false,
            },
            {
              label: "Dashboard",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    icon: <HiUserGroup />,
    requiredClaim: {
      freight_c: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Freight Bookings",
      icon: <FaTruckMoving />,
    },
  },
  {
    items: [
      {
        title: "Wholesale Arrivals",
        to: "/freight-booking/wholesale-wallboard-arrivals",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-booking/wholesale-wallboard-arrivals",
          component: <WallboardWholesale type="arrivals" />,
          requiredClaim: {
            freight_report: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Finance",
              pos: 1,
              active: false,
            },

            {
              label: "WholeSale Arrivals",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Wholesale Arrivals (New)",
        to: "/freight-booking/wholesale-wallboard-new",
        showMenuItem: true,
        routeInfo: {
          path: "/freight-booking/wholesale-wallboard-new",
          component: <WallboardWholesaleNew type="arrivals" />,
          requiredClaim: {
            freight_report: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Finance",
              pos: 1,
              active: false,
            },

            {
              label: "WholeSale Arrivals (New)",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    requiredClaim: {
      freight_report: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Wallboards",
      icon: <BsTv />,
    },
  },
  {
    items: [
      {
        title: "CBA Collateral Reports",
        to: "/finance/cba-collateral-reports",
        showMenuItem: true,
        routeInfo: {
          path: "/finance/cba-collateral-reports",
          component: <CollateralReportsComponent />,
          requiredClaim: {
            finance_secure: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Finance",
              pos: 1,
              active: false,
            },

            {
              label: "CBA Collateral Reports",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    requiredClaim: {
      finance_secure: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Finance",
      icon: <HiOutlineCash />,
    },
  },
  {
    items: [
      {
        title: "Internal Auctions",
        to: "/auctions/internal-tenders",
        showMenuItem: true,
        routeInfo: {
          path: "/auctions/internal-tenders",
          component: <InternalTenders />,
          requiredClaim: {
            auction_internal: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Auctions",
              pos: 1,
              active: false,
            },
            {
              label: "Internal Tenders",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "Internal Auctions - Seller",
        to: "/auctions/internal-tenders/seller/:auctionId/:vehicleId",
        showMenuItem: false,
        routeInfo: {
          path: "/auctions/internal-tenders/seller/:auctionId/:vehicleId",
          component: <InternalTendersVehicleSeller />,
          requiredClaim: {
            auction_internal: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Auctions",
              pos: 1,
              active: false,
            },
            {
              label: "Internal Tenders",
              pos: 2,
              active: false,
            },
            {
              label: "Seller",
              pos: 3,
              active: false,
            },
            {
              label: "Bid Details",
              pos: 4,
              active: false,
            },
          ],
        },
      },
      {
        title: "Internal Auctions - Bidder",
        to: "/auctions/internal-tenders/bidder/:auctionId/:vehicleId",
        showMenuItem: false,
        routeInfo: {
          path: "/auctions/internal-tenders/bidder/:auctionId/:vehicleId",
          component: <InternalTendersVehicleBidder />,
          requiredClaim: {
            auction_internal: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Auctions",
              pos: 1,
              active: false,
            },
            {
              label: "Internal Tenders",
              pos: 2,
              active: false,
            },
            {
              label: "Seller",
              pos: 3,
              active: false,
            },
            {
              label: "Bid Details",
              pos: 4,
              active: false,
            },
          ],
        },
      },
    ],
    requiredClaim: {
      auction_internal: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Auctions",
      icon: <FaGavel />,
    },
  },
  {
    items: [
      {
        title: "User Management",
        to: "/settings",
        showMenuItem: true,
        routeInfo: {
          path: "/settings",
          component: <UpdateUserComponent />,
          requiredClaim: {
            su: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Settings",
              pos: 1,
              active: false,
            },
            {
              label: "User Management",
              pos: 2,
              active: true,
            },
          ],
        },
      },
      {
        title: "View Users  ",
        to: "/viewUsers/:id",
        showMenuItem: false,
        routeInfo: {
          path: "/viewUsers/:userid",
          component: <ViewUsersComponent />,
          requiredClaim: {
            su: true,
          },
          breadCrumbs: [
            {
              label: "Data Portal",
              pos: 0,
              active: false,
            },
            {
              label: "Settings",
              pos: 1,
              active: false,
            },
            {
              label: "User Management",
              pos: 2,
              active: false,
            },

            {
              label: "User Details",
              pos: 2,
              active: true,
            },
          ],
        },
      },
    ],
    requiredClaim: {
      su: true,
    },
    subMenu: true,
    subMenuInfo: {
      label: "Settings",
      icon: <HiOutlineCog />,
    },
  },
];

const RouterComponentNew = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [rtl, setRtl] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [theme, setTheme] = useState("light");
  const [globalMessage, setGlobalMessage] = useState({});
  const [pinSidebar, setPinSidebar] = useState(false);
  const [showNewView, setShowNewView] = useState(true);
  const [isDev, setIsDev] = useState(false);
  const [toastShown, setToastShown] = useState(true);

  const user = useUser();
  const auth = useAuth();

  const firestore = useFirestore(); // @COMPATIBILITY
  const functions = getFunctions();

  //   useEffect(() => {
  //     // Global message listener
  //     const unsubscribe = firestore
  //       .collection("settings")
  //       .doc("global-messages")
  //       .onSnapshot((doc) => {
  //         if (doc.exists) {
  //           setGlobalMessage(doc.data());
  //         }
  //       });

  //     return () => {
  //       unsubscribe();
  //     };
  //   }, []);
  const handlePinSideBar = () => {
    // Get state of sidebar from local storage
    setPinSidebar(!pinSidebar);
    localStorage.setItem("pinSidebar", !pinSidebar);
  };

  useEffect(() => {
    const pinSidebarLoc = localStorage.getItem("pinSidebar");
    if (pinSidebarLoc === null) {
      // If no value in local storage, set to true
      localStorage.setItem("pinSidebar", pinSidebar);
    } else {
      console.log(pinSidebarLoc);
      setPinSidebar(JSON.parse(pinSidebarLoc));
    }
  }, [pinSidebar]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname === "localhost") {
      setIsDev(true);
      // Database
      // console.log(`Setting up emulator for firestore on localhost:8080`);
      // connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
      // Functions
      // functions.useEmulator("localhost", 5000);
      console.log(`Setting up emulator for functions on localhost:5001`);
      connectFunctionsEmulator(functions, "localhost", 5000);
      // Authentication
      // auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
    }
  }, []);

  useEffect(() => {
    // Show message if user has not dismissed

    // Check cookie to see if user has dismissed the message
    if (document.cookie.includes("dismissPhoneSignIn=true")) {
      return;
    }
    setToastShown(false);
  }, [toastShown]);

  return (
    <React.Suspense fallback={<SuspenseLoadingFallback />}>
      <Router>
        <div
          style={{
            display: "flex",
            height: "100%",
            direction: "ltr",
          }}
        >
          <Sidebar
            id="main-sidebar"
            collapsed={collapsed}
            toggled={toggled}
            onBackdropClick={() => {
              setToggled(false);
            }}
            onBreakPoint={setBroken}
            rtl={rtl}
            breakPoint={pinSidebar ? "md" : "all"}
            backgroundColor="rgb(255,255,255,1)"
            width="220px"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
              }}
            >
              <div style={{ height: "60px", marginTop: 20, cursor: "pointer" }}>
                {!collapsed ? (
                  <>
                    <Image
                      src={DALogo}
                      alt="DA Logo"
                      height={30}
                      onClick={() => {
                        window.location.href = "/";
                      }}
                    />{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                        fontWeight: 600,
                        float: "right",
                        marginRight: 10,
                        fontSize: 14,
                      }}
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      {`|<`}
                    </span>
                  </>
                ) : (
                  <span
                    style={{ color: "#44596e", fontSize: 16, fontWeight: 600 }}
                  >
                    DA
                    <span
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                        fontWeight: 600,
                        float: "right",
                        marginRight: 10,
                        fontSize: 14,
                      }}
                      onClick={() => setCollapsed(!collapsed)}
                    >
                      {`>|`}
                    </span>
                  </span>
                )}
                <hr />
              </div>
              <div style={{ flex: 1, paddingBottom: 30 }}>
                <Menu
                  closeOnClick={true}
                  menuItemStyles={{
                    root: {
                      fontSize: "13px",
                      fontWeight: 400,
                      textAlign: "left",
                      opacity: 1,
                    },

                    label: ({ open }) => ({
                      fontWeight: open ? 600 : undefined,
                    }),
                    button: {
                      // the active class is added automatically by react router
                      [`&.active`]: {
                        backgroundColor: "#3498db",
                        color: "#f0f0f0",
                      },
                    },
                  }}
                >
                  {/* Iterate through and render menu items */}
                  {menuItems.map((item, index) => (
                    <SideBarMenuItem
                      key={index}
                      items={item.items}
                      requiredClaim={item.requiredClaim}
                      hasSubMenu={item.subMenu}
                      subMenuInfo={item.subMenuInfo}
                    />
                  ))}

                  <div
                    style={{
                      padding: "0 24px",
                      marginBottom: "8px",
                      marginTop: "32px",
                    }}
                  ></div>

                  <div
                    style={{
                      position: "fixed",
                      bottom: 0,
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 10,
                    }}
                  ></div>
                </Menu>
              </div>
            </div>
          </Sidebar>

          {/* Main Section rendering content from active menu item */}

          <main
            style={{
              width: "100%",
              overflowY: "auto",
              // hide scrollbar
              scrollbarWidth: "none",
            }}
          >
            <div
              style={{
                backgroundColor: isDev ? "#e74c3c" : "#2980b9",
                color: "white",
                textAlign: "center",
                padding: 2,
                fontWeight: 600,
              }}
            ></div>
            {broken ? (
              <div
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  marginLeft: 10,
                  textAlign: "left",
                  position: "relative",
                }}
              >
                <Button
                  variant="dark"
                  size="md"
                  onClick={() => setToggled(!toggled)}
                >
                  <BsList />
                </Button>
                <span style={{ marginLeft: 10 }}>Dutton Data Portal</span>
                <Button
                  variant="outline-dark"
                  onClick={() => {
                    auth.signOut();
                  }}
                  style={{
                    textAlign: "right",
                    fontSize: 15,
                    float: "right",
                    marginRight: 10,
                  }}
                >
                  <BiExit />
                </Button>
                <Button
                  onClick={handlePinSideBar}
                  variant={pinSidebar ? "dark" : "light"}
                  style={{
                    textAlign: "right",
                    fontSize: 15,
                    float: "right",
                    marginRight: 10,
                  }}
                >
                  <BiPin />
                </Button>
              </div>
            ) : (
              <p
                style={{
                  marginTop: 15,
                  marginLeft: 30,
                  textAlign: "left",
                  fontWeight: 500,
                }}
              >
                Welcome to the Dutton Data Portal
                {/* <span
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    marginLeft: 50,
                    color: "#8e44ad",
                    fontStyle: "italic",
  
                  }}
                >
                  {globalMessage?.bannerMessage}
                </span> */}
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                    marginRight: 20,
                    float: "right",
                  }}
                >
                  <Dropdown>
                    <span
                      style={{
                        backgroundColor: "#3498db",
                        color: "#FFF",
                        padding: 10,
                        textAlign: "left",
                        margin: 10,
                        borderRadius: 5,
                      }}
                    >
                      {user?.data?.displayName?.charAt(0)}
                    </span>
                    <span style={{ marginRight: 10 }}>
                      {user?.data?.displayName}
                    </span>
                    <Dropdown.Toggle
                      variant="outline"
                      split
                      id="dropdown-user"
                      style={{ fontSize: 12, padding: 10 }}
                    />
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          auth.signOut();
                        }}
                        style={{ textAlign: "right", fontSize: 14 }}
                      >
                        <DoorClosed style={{ fontSize: 20, marginRight: 5 }} />
                        Sign Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
                <Button
                  onClick={handlePinSideBar}
                  variant={pinSidebar ? "dark" : "light"}
                  style={{
                    textAlign: "right",
                    fontSize: 15,
                    float: "right",
                    marginRight: 10,
                  }}
                >
                  <BiPin />
                </Button>
              </p>
            )}

            <div
              style={{
                padding: "16px 24px",
                color: "#44596e",
              }}
            >
              {/* Banner */}

              <Switch>
                {/* Iterate through and render route items */}
                {menuItems.map((menuItem, index) => {
                  return menuItem.items.map((item, i) => {
                    return (
                      <Route
                        exact
                        key={`${index}-${i}`}
                        path={item?.routeInfo?.path}
                        render={() => (
                          <AuthCheckCustom
                            requiredClaims={item?.routeInfo?.requiredClaim}
                            fallback={<NoAuth />}
                          >
                            {!broken && (
                              <Breadcrumb style={{ fontSize: 12 }}>
                                <Breadcrumb.Item
                                  active={false}
                                  onClick={() => {
                                    window.history.back();
                                  }}
                                >
                                  <HiArrowLeft /> Back to previous page
                                </Breadcrumb.Item>
                                {item?.routeInfo?.breadCrumbs.map(
                                  (crumb, index) => (
                                    <Breadcrumb.Item key={index} active={true}>
                                      {crumb.label}
                                    </Breadcrumb.Item>
                                  )
                                )}
                              </Breadcrumb>
                            )}
                            {item?.routeInfo?.component}
                          </AuthCheckCustom>
                        )}
                      />
                    );
                  });
                })}
              </Switch>
            </div>
          </main>
        </div>
      </Router>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          display: toastShown ? "none" : "flex",
          textAlign: "left",
          marginBottom: 10,
          zIndex: 9999,
          backgroundColor: "#333",
          borderRadius: 8,
          padding: "8px 10px",
          maxWidth: 350,
          lineHeight: 1.3,
          boxShadow: "0 3px 10px rgba(0,0,0,0.2)",
          marginRight: 10,
        }}
      >
        <span
          style={{
            padding: 10,
            backgroundColor: "#333",
            color: "white",
            textAlign: "left",
            width: "100%",
            fontSize: 14,
          }}
        >
          We will soon be removing the ability to sign in with your phone
          number. Please use your Dutton ID to sign in and if you have any
          issues, please contact support.
          <p
            style={{
              color: "white",
              cursor: "pointer",
              textDecoration: "underline",
              marginTop: 10,
              fontSize: 12,
            }}
            onClick={() => {
              // Set a cookie to dismiss the message max age 5 days
              document.cookie = "dismissPhoneSignIn=true;max-age=432000";
              setToastShown(true);
            }}
          >
            Dismiss this message for 5 days
          </p>
        </span>
      </div>
    </React.Suspense>
  );
};

export default RouterComponentNew;
